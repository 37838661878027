<template>
    <header class="navbar">
        <div
            class="header"
            @mouseleave=";(subabout = false), (subservices = false)"
            v-if="globalsIsLoaded"
        >
            <router-link :to="{ name: 'HomePage-' + $route.meta.lang }" class="logo">
                <img src="@/assets/img/logo-icon.svg" alt="Logo St-Denis Thompson" class="icon" />
                <img src="@/assets/img/logo-text.svg" alt="Logo St-Denis Thompson" class="text" />
            </router-link>
            <nav>
                <div class="drop" @mouseover=";(subabout = true), (subservices = false)">
                    <router-link :to="{ name: 'About-' + $route.meta.lang }" class="link">{{
                        $t('À propos')
                    }}</router-link>
                    <svg
                        class="arrow"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.75 1.19995L4.5 4.44995L1.25 1.19995"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <div
                        class="sub_menu"
                        :class="{ actif: subabout }"
                        @mouseleave=";(subabout = false), (subservices = false)"
                    >
                        <router-link :to="{ name: 'Historique-' + $route.meta.lang }">{{
                            $t('Historique')
                        }}</router-link>
                        <router-link :to="{ name: 'Direction-' + $route.meta.lang }">{{
                            $t('Direction')
                        }}</router-link>
                        <router-link :to="{ name: 'Prix-' + $route.meta.lang }">{{
                            $t('Prix et distinctions')
                        }}</router-link>
                        <img src="@/assets/img/triangle.svg" alt="triangle" class="triangle" />
                    </div>
                </div>
                <router-link
                    :to="{ name: 'Realisations-' + $route.meta.lang }"
                    class="link"
                    @mouseover=";(subabout = false), (subservices = false)"
                    >{{ $t('Réalisations') }}</router-link
                >
                <div class="drop" @mouseover=";(subservices = true), (subabout = false)">
                    <router-link :to="{ name: 'Services-' + $route.meta.lang }" class="link">{{
                        $t('Services')
                    }}</router-link>
                    <svg
                        class="arrow"
                        viewBox="0 0 9 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.75 1.19995L4.5 4.44995L1.25 1.19995"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <div
                        class="sub_menu"
                        :class="{ actif: subservices }"
                        @mouseleave=";(subservices = false), (subabout = false)"
                    >
                        <router-link :to="globals.servicesEntry.data[0].page.jsonUrl">{{
                            globals.servicesEntry.data[0].page.titre
                        }}</router-link>
                        <router-link :to="globals.servicesEntry.data[1].page.jsonUrl">{{
                            globals.servicesEntry.data[1].page.titre
                        }}</router-link>
                        <router-link :to="globals.servicesEntry.data[2].page.jsonUrl">{{
                            globals.servicesEntry.data[2].page.titre
                        }}</router-link>
                        <img src="@/assets/img/triangle.svg" alt="triangle" class="triangle" />
                    </div>
                </div>
                <router-link
                    :to="{ name: 'Carrieres-' + $route.meta.lang }"
                    class="link"
                    @mouseover=";(subabout = false), (subservices = false)"
                    >{{ $t('Carrières') }}</router-link
                >
                <router-link
                    :to="{ name: 'Contact-' + $route.meta.lang }"
                    class="link cta"
                    @mouseover=";(subabout = false), (subservices = false)"
                    >{{ $t('Nous joindre') }}</router-link
                >
                <router-link @mouseover=";(subabout = false), (subservices = false)" v-if="pageIsLoaded && $route.meta.lang === 'fr'" :to="content.urls['en']" class="link lang">EN</router-link>
                <router-link @mouseover=";(subabout = false), (subservices = false)" v-else :to="pageIsLoaded && content.urls['fr'] ? content.urls['fr'] : ''" class="link lang">FR</router-link>
            </nav>
            <div class="burger" @click.prevent="showMobileMenu">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>

        <div class="mobile_menu" :class="{ show: menuIsOpen }" v-if="globalsIsLoaded" @click.stop="menuIsOpen = false">
            <div class="menu">
                <img
                    src="@/assets/img/close.svg"
                    class="close"
                    alt="Close"
                    @click.prevent="showMobileMenu"
                />
                <nav>
                    <router-link
                        :to="{ name: 'About-' + $route.meta.lang }"
                        class="title small line"
                        >{{ $t('À propos') }}</router-link
                    >
                    <div class="sub">
                        <router-link
                            :to="{ name: 'Historique-' + $route.meta.lang }"
                            class="regular-text"
                            >{{ $t('Historique') }}</router-link
                        >
                        <router-link
                            :to="{ name: 'Direction-' + $route.meta.lang }"
                            class="regular-text"
                            >{{ $t('Direction') }}</router-link
                        >
                        <router-link
                            :to="{ name: 'Prix-' + $route.meta.lang }"
                            class="regular-text"
                            >{{ $t('Prix et distinctions') }}</router-link
                        >
                    </div>
                    <router-link
                        :to="{ name: 'Realisations-' + $route.meta.lang }"
                        class="title small line"
                        >{{ $t('Réalisations') }}</router-link
                    >
                    <router-link
                        :to="{ name: 'Services-' + $route.meta.lang }"
                        class="title small line"
                        >{{ $t('Services') }}</router-link
                    >
                    <div class="sub">
                        <router-link
                            class="regular-text"
                            :to="globals.servicesEntry.data[0].page.jsonUrl"
                            >{{ globals.servicesEntry.data[0].page.titre }}</router-link
                        >
                        <router-link
                            class="regular-text"
                            :to="globals.servicesEntry.data[1].page.jsonUrl"
                            >{{ globals.servicesEntry.data[1].page.titre }}</router-link
                        >
                        <router-link
                            class="regular-text"
                            :to="globals.servicesEntry.data[2].page.jsonUrl"
                            >{{ globals.servicesEntry.data[2].page.titre }}</router-link
                        >
                    </div>
                    <router-link
                        :to="{ name: 'Carrieres-' + $route.meta.lang }"
                        class="title small line"
                        >{{ $t('Carrières') }}</router-link
                    >
                    <router-link :to="{ name: 'Contact-' + $route.meta.lang }" class="link cta">{{
                        $t('Nous joindre')
                    }}</router-link>

                    <a href="#" class="title lang">En</a>
                </nav>
            </div>
            <div class="overlay" @click.prevent="showMobileMenu"></div>
        </div>
    </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
    data() {
        return {
            subabout: false,
            subservices: false,
            menuIsOpen: false,
        }
    },
    methods: {
        showMobileMenu() {
            if (this.menuIsOpen) {
                this.menuIsOpen = false
            } else {
                this.menuIsOpen = true
            }
        },
    },
    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/02-Tools/variables.scss';
</style>
